import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        We are a small, family-run pizza take away that specialises in home
        delivery.
      </Typography>

      <Typography variant="body1" paragraph>
        We offer a broad range of healthy thin crust pizzas and salads as well
        as a variety of garlic breads and desserts. We have all that you'll need
        for the perfect lunch or dinner. Our delicious pizzas are made to order
        and baked to perfection in our state of the art oven.
      </Typography>

      <Typography variant="body1" paragraph>
        Louie and I are passionate about food and delivery of that food. We give
        100% each day to provide the best service available in London.
      </Typography>

      <Typography variant="body1" paragraph>
        We are always looking for ways to improve so if you have any comments
        that would help us to meet your needs better then please contact us and
        share your thoughts.
      </Typography>

      <Typography variant="body1" paragraph>
        We look forward to seeing you soon,
      </Typography>

      <Typography variant="body1" paragraph>
        Louie and Svetlana
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
